import React, {Component} from 'react';
import Sectiontitle from '../Banner/Sectiontitle';
import {LightBox} from "react-lightbox-pack";
import "react-lightbox-pack/dist/index.css";
import {PageDataContext} from "../../services/context";
import {Loader} from "../Loader";

const gallery = {
  darwin: [
    { id:0, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/darwin_0.jpg'},
    { id:1, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/darwin_1.jpg'},
    { id:2, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/darwin_2.jpg'},
    { id:3, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/darwin_3.jpg'}
    // { id:4, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/darwin_4.jpg'}
  ],
  bareja: [
    { id:0, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/bareja_0.jpg'},
    { id:1, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/bareja_1.jpg'},
    { id:2, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/bareja_2.jpg'},
    { id:3, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/bareja_3.jpg'}
  ],
  matejko: [
    // { id:0, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/matejko_0.jpg'},
    { id:1, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/matejko_1.jpg'},
    { id:2, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/matejko_2.jpg'}
    // { id:3, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/matejko_3.jpg'}
    // { id:4, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/matejko_4.jpg'}

  ],
  nikifor: [
    { id:0, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/nikifor_0.jpg'},
    { id:1, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/nikifor_1.jpg'},
    { id:2, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/nikifor_2.jpg'}    
  ],
  szekspir: [
    { id:0, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_0.jpg'},
    { id:1, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_1.jpg'},
    { id:2, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_2.jpg'},
    { id:3, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_3.jpg'},
    // { id:4, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_4.jpg'},
    // { id:5, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_5.jpg'},
    // { id:6, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_6.jpg'},
    // { id:7, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_7.jpg'},
    // { id:8, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_8.jpg'},
    // { id:9, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_9.jpg'},
    // { id:10, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_10.jpg'},
    { id:11, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_11.jpg'},
    { id:12, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_12.jpg'},
    { id:13, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_13.jpg'},
    { id:14, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_14.jpg'},
    { id:15, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_15.jpg'},
    // { id:16, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_16.jpg'},
    { id:17, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/szekspir_17.jpg'}
  ],
  tesla: [
    { id:0, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/tesla_0.jpg'},
    { id:1, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/tesla_1.jpg'},
    { id:2, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/tesla_2.jpg'}
  ],
  mrozek: [
    { id:0, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/mrozek_0.jpg'},
    { id:1, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/mrozek_1.jpg'},
    { id:2, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/mrozek_2.jpg'},
    { id:3, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/mrozek_3.jpg'},
    { id:4, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/mrozek_4.jpg'}
  ],
  pina: [
    { id:0, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/pina_0.jpg'},
    { id:1, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/pina_1.jpg'},
    { id:2, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/pina_2.jpg'},
    { id:3, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/pina_3.jpg'}
  ],
  kora: [
    { id:0, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/kora_0.jpg'},
    { id:1, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/kora_1.jpg'},
    { id:2, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/kora_2.jpg'},
    { id:3, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/kora_3.jpg'}
  ],
  isadora: [
    { id:0, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/isadora_0.jpg'},
    { id:1, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/isadora_1.jpg'},
    { id:2, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/isadora_2.jpg'},
    { id:3, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/isadora_3.jpg'},
    { id:4, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/isadora_4.jpg'}
  ],
  korytarze: [
    { id:0, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_0.jpg'},
    { id:1, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_1.jpg'},
    { id:2, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_2.jpg'},
    { id:3, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_3.jpg'},
    // { id:4, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_4.jpg'},
    // { id:5, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_5.jpg'},
    { id:6, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_6.jpg'},
    { id:7, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_7.jpg'},
    { id:8, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_8.jpg'},
    { id:9, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_9.jpg'},
    // { id:10, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_10.jpg'},
    { id:11, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_11.jpg'},
    { id:12, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_12.jpg'},
    { id:13, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_13.jpg'},
    { id:14, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_14.jpg'},
    { id:15, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_15.jpg'},
    { id:16, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_16.jpg'},
    { id:16, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_17.jpg'},
    { id:16, image:'https://alternatywy.s3.eu-central-1.amazonaws.com/sale/korytarze_18.jpg'}
  ]
};

class Rent extends Component {
  static contextType = PageDataContext;

  constructor(props) {
    super(props);

    this.state = {
      toggle: false,
      index: 0,
      room: '',
      gallery: [],
    }

    this.tableRef = null;
    this.setTableRef = (element) => {
      this.tableRef = element;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.tableRef) {
      this.tableRef.querySelectorAll('a').forEach(item => {
        if (item.href.includes('#')){
          const [,room] = item.href.split('#');
          item.dataset.room = room;
          item.addEventListener('click', this.showLightbox.bind(this));
        }
      })
    }
  }

  showLightbox(event){
    const { dataset: { room } } = event.currentTarget;
    this.setState({
      toggle: true, index: 0, room: room, gallery: gallery[room] || []
    });
  }

  hideLightbox(){
    this.setState({
      toggle: false, index: 0, room: '', gallery: []
    });
  }

  setImageIndex(idx){
    if (typeof idx === 'function'){
      this.setState({ index: idx(this.state.index) });
    }
  }

  render() {
    const { dataState: {rent} } = this.context;
    // if (!rent || rent.length === 0) return null;
    const [rentContent] = rent;

    return (
      <section className="rent_area" id="rent">
        {
          (!rent || rent.length === 0) ?
            <Loader></Loader>
            : <div className="container">
              <Sectiontitle Title="Wynajem sal"/>
              <div className="row" ref={this.setTableRef} dangerouslySetInnerHTML={{__html: rentContent.content}}/>
            </div>
        }
        {/* render lightbox gallery */}
        {this.state.gallery.length >0 && <LightBox
          state={this.state.toggle}
          event={this.hideLightbox.bind(this)}
          data={this.state.gallery}
          imageWidth="70vw"
          imageHeight="80vh"
          thumbnailHeight={50}
          thumbnailWidth={50}
          setImageIndex={this.setImageIndex.bind(this)}
          imageIndex={this.state.index}
          />}
      </section>
    )
  }
}

export default Rent;
