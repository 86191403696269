import React, {useEffect} from "react";
import Navbar from "./component/Navbar/Navbar";
import Press from "./component/Press/Press";
import Footer from "./component/Footer";
import jhonData from "./component/jhonData";
import RepertoryDetails from "./component/Repertory/RepertoryDetails";

export const PressPage = ({ match, location, history }) => {
    const {id} = match.params
    let type = 'press';
    if (location.state?.fromNews || location.state?.fromNewsArchive){
      type = 'news';
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <div className="body_wrapper">
          <Navbar mClass="dark_menu" mContainer="custome_container" mainlogo="logo.svg" />
          {id ? <RepertoryDetails id={id} type={type}/> : <Press/> }
          <Footer jhonData={jhonData} />
      </div>
    );
}
