import React, { Component } from "react";
import Reveal from "react-reveal/Reveal/";
import {PageDataContext} from '../services/context';

class Contact extends Component {
  static contextType = PageDataContext;

  render() {
    const { dataState: {contact} } = this.context;
    if (!contact || contact.length === 0) return null;
    const [{ content, title }] = contact;

    return (
      <section className="contact-area" id="contact">
        <div className="container">
          <Reveal effect="fadeInLeft" duration={500}>
            <h4>{ title }</h4>
            <div className="contact_info" dangerouslySetInnerHTML={{__html: content}} />
          </Reveal>
        </div>
      </section>
    );
  }
}
export default Contact;
