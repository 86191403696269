import React, { useReducer, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
/*-----pages-----*/

import { Home3 } from "./Home3";
import { PressPage } from "./PressPage";
import { PublicPage } from "./PublicPage";
import { AboutUsPage } from "./AboutUsPage";
import { RentPage } from "./RentPage";
import { ExhibitionPage } from "./ExhibitionPage";
import { OrdinancesPage } from "./OrdinancesPage";
import { ArchivePage } from "./ArchivePage";
import { AvailabilityDeclarationPage } from "./AvailabilityDeclarationPage";
// import { Home4 } from "./Home4";
// import { Home5 } from "./Home5";
// import { Home6 } from "./Home6";
// import { Homefrelencer } from "./Home-frelencer";
// import { Creativecarousel } from "./Creative-carousel";
// import { HomeDeveloper } from "./Home-developer";
import { NotFound } from "./404";
import { PageDataContext } from './services/context';
import {getNews, getRepertory} from "./services";
import {parseRepertoryResponse} from "./services/transform";

const initialState = {
  exhibition: [],
  publicOrders: [],
  regulations: [],
  news: [],
  rent: [],
  newsArchive: [],
  press: [],
  aboutDepartments: [],
  availabilityDeclaration: [],
};

function reducer(state, action) {
  let newState;
  switch (action.type) {
    case 'setPublicOrders':
      newState = { ...state, publicOrders: [...action.payload] };
      break;
    case 'setExhibition':
      newState = { ...state, exhibition: [...action.payload] };
      break;
    case 'setNews':
      newState = { ...state, news: [...action.payload] };
      break;
    case 'setAbout':
      newState = { ...state, about: [...action.payload] };
      break;
    case 'setRent':
      newState = { ...state, rent: [...action.payload] };
      break;
    case 'setRegulations':
      newState = { ...state, regulations: [...action.payload] };
      break;
    case 'setContact':
      newState = { ...state, contact: [...action.payload] };
      break;
    case 'setNewsArchive':
      newState = { ...state, newsArchive: [...action.payload] };
      break;
    case 'setPress':
      newState = { ...state, press: [...action.payload] };
      break;
    case 'setAboutDepartments':
        newState = { ...state, aboutDepartments: [...action.payload] };
        break;
    case 'setAvailabilityDeclaration':
        newState = { ...state, availabilityDeclaration: [...action.payload] };
        break;
     // multiple
    case 'setMultiple':
      newState = { ...state, ...action.payload };
      break;
    default:
      newState = { ...state };
      break;
  }
  return newState;
}

export const App = () => {
  const [dataState, dataDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
      Promise.all([
        getRepertory(),
        getNews()
      ]).then(async (promises) => {
        const [
          repertory,
          { data: { press, news, newsarchive, publicorders, exhibition, regulations, contact, about, rent, aboutdepartments, availabilitydeclaration }}
        ] = promises;

        try {
          await dataDispatch({
            type: 'setMultiple',
            payload: {
              repertory: parseRepertoryResponse(repertory),
              regulations: regulations.edges ? regulations.edges.map(item => item.node) : [],
              exhibition: exhibition.edges ? exhibition.edges.map(item => item.node) : [],
              news: news.edges ? news.edges.map(item => item.node) : [],
              newsArchive: newsarchive.edges ? newsarchive.edges.map(item => item.node) : [],
              about: about.edges ? about.edges.map(item => item.node) : [],
              contact: contact.edges ? contact.edges.map(item => item.node) : [],
              rent: rent.edges ? rent.edges.map(item => item.node) : [],
              publicOrders: publicorders.edges ? publicorders.edges.map(item => item.node) : [],
              press: press.edges ? press.edges.map(item => item.node) : [],
              aboutDepartments: aboutdepartments.edges ? aboutdepartments.edges.map(item => item.node) : [],
              availabilityDeclaration: availabilitydeclaration.edges ? availabilitydeclaration.edges.map(item => item.node) : []
            }
          });
        } catch (error){
          console.error('>>> useEffect dataDispatch', error);
        }
      }).catch(console.error);
  }, []);

  return (
    <PageDataContext.Provider value={{ dataState, dataDispatch }}>
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Home3} />
            <Route path="/press/:id" component={PressPage} />
            <Route path="/press" component={PressPage} />
            <Route path="/aboutus" component={AboutUsPage} />
            <Route path="/ordinances" component={OrdinancesPage} />
            <Route path="/public" component={PublicPage} />
            <Route path="/rent" component={RentPage} />
            <Route path="/exhibition" component={ExhibitionPage} />
            <Route path="/archive" component={ArchivePage} />
            <Route path="/availabilitydeclaration" component={AvailabilityDeclarationPage} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </React.Fragment>
    </PageDataContext.Provider>
  );
}

export default App;
