import React , {Component} from 'react';
import Sectiontitle from '../Banner/Sectiontitle';
import PressItems from '../Press/PressItems';
import Reveal from 'react-reveal/Reveal/';
import {PageDataContext} from '../../services/context';
import {Loader} from "../Loader";

class Press extends Component{
    static contextType = PageDataContext;
    constructor(props) {
        super(props);
        this.state = {
            press:[],
        }
    }

    render(){
        let { dataState: {press} } = this.context;
        return(
            <section className="press_area" id="press">
                {
                  (!press || press.length === 0) ?
                  <Loader></Loader>
                  :
                  <div className="container">
                      <Sectiontitle Title="Do pobrania" TitleP="Informacje prasowe"/>
                      <Reveal effect="fadeInUp" duration={1000}>
                          <div className="row">
                              {
                                  press.map((item, key) =>
                                    <PressItems key={key} bTitle={item.title} bDetails={item.excerpt} idx={item.postId}
                                                image="press.png"/>
                                  )
                              }
                          </div>
                      </Reveal>
                  </div>
                }
            </section>
        )
    }
}
export default Press;
