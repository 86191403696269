const jhonData = {
  name: "John Deo.",
  name2: "Richard Tea.",
  name3: "Alexa Johnson",
  role: "Ursynowskie Centrum Kultury",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "Facebook",
      url: "https://www.facebook.com/alternatywy.art/",
      className: "social_facebook",
    },
    {
      name: "twitter",
      url: "https://twitter.com/alternatywy_art",
      className: "social_twitter",
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/alternatywy.art/",
      className: "social_instagram",
    },
  ],
  aboutme: "O nas",
  aboutdetails:
    `<p>Ursynowskie Centrum Kultury „Alternatywy” to nowoczesna, wyczekiwana przez mieszkańców dzielnicy instytucja kultury. Inauguracja działalności nastąpi 17 września 2021 roku. Misją „Alternatyw” jest promowanie istotnych zjawisk z obszaru muzyki, filmu, teatru, literatury i sztuk wizualnych, a także szeroko pojęta edukacja kulturalna skierowana do wszystkich grup wiekowych. W nowoczesnym budynku przy ul. Indiry Gandhi 9 od rana do późnych godzin wieczornych będą odbywały się warsztaty, spektakle teatralne, koncerty, seanse filmowe, debaty oraz spotkania autorskie. Nazwa instytucji, wybrana przez mieszkańców Ursynowa, nawiązuje do słynnego serialu Stanisława Barei „Alternatywy 4”, jednak oznacza też bogatą i przekrojową ofertę kulturalną, dającą liczne możliwości spędzania czasu wolnego i osobistego rozwoju.</p>
      <h4>Struktura organizacyjna</h4>
      <p>
        <strong>Dyrektor</strong><br/>
        <span>dr Krzysztof Czubaszek</span>
        <br/>
        <strong>Zastępca Dyrektora ds. Programowych</strong><br/>
        <span>Marek Kałużyński</span>
        <br/>
        <strong>Zastępca Dyrektora ds. Organizacyjnych</strong><br/>
        <span>dr Arkadiusz Kujawa</span>
      </p>    
    `,
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Web Design",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Development",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Marketing",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Strategy",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Support",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 6,
      iconName: "icon-tools",
      serviceTitle: "We’re experienced",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
  ],
  contact: [
    {
      id: "1",
      title: "Informacje kontaktowe",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Adres: ul. Indiry Gandhi 9, <br/> 02-776 Warszawa, Polska <br/> NIP: 9512519023 <br/> REGON: 388837020",
          href: "https://www.google.com/maps/place/Indiry+Gandhi+9,+02-776+Warszawa",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Telefon: +48 22 276 20 60 (wew. 00) Sekretariat",
          href: "tel: +48222762060",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email (tylko w sprawach administracyjnych): biuro@alternatywy.art",
          href: "mailto: biuro@alternatywy.art",
        }
      ],
    },
    {
      id: "2",
      title: "Recepcja",
      menuItems: [
        {
          id: 1,
          icon: "icon_search-2",
          text: `Godziny Pracy Recepcji:
          <br/>pon.–pt. 9:00–20:15
          <br/>sob.–niedz. 12:00–20:15
          `,
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Telefon: +48 22 276 20 84 (wew. 24)",
          href: "tel: +48222762084",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: recepcja@alternatywy.art",
          href: "mailto: recepcja@alternatywy.art",
        },
      ],
    },
    {
      id: "3",
      title: "Współpraca",
      menuItems: [
        {
          id: 1,
          icon: "icon_group",
          text: `Propozycje współpracy w zakresie organizacji koncertów, spektakli, prowadzenia zajęć etc. prosimy kierować pod adres:  <span>wspolpraca@alternatywy.art</span>`,
          href: "mailto: wspolpraca@alternatywy.art",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Front-End Developer",
      title: "Talk is cheap. \n Show me the code",
      discription:
        "I design and code beautifully simple things,\n and I love what I do.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Introduce",
      aHead: "Passion \n Heart & Soul",
      aHeadTwo: "Every great design begin with an even better story",
      adiscription:
        "Since beginning my journey as a freelancer designer nearly 4 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Design",
      text: "Create digital products with unique idea",
      complateProject: "20 Projects",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "front-End",
      text: "My coding structure is clean and smooth",
      complateProject: "165 Projects",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "SEO",
      text: "Boost your business with SEO optimize.",
      complateProject: "5 Projects",
      icon: "icon-linegraph",
    },
  ],
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "Formularz kontaktowy",
};

export default jhonData;
