import React, {Component, useCallback} from "react";
import Sticky from "react-stickynode";
import { Link as LinkScroll } from "react-scroll";
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';

const menu = [
  { name: 'Aktualności', to: 'news' },
  { name: 'Repertuar', to: 'repertory' },
  { name: 'Zajęcia', to: 'https://www.strefazajec.pl/course?city_id=42&company_id=1254' },
  { name: 'Wystawy', to: '/exhibition' },
  { name: 'Wynajem sal', to: '/rent' },
  { name: 'O nas', to: 'about' },
  { name: 'Kontakt', to: 'contact' },
];

const AccesibilityFontSize = () => {

  const applyFontSize = useCallback((event) => {
    const { level } = event.currentTarget.dataset;
    for (let [, value] of document.body.classList.entries()) {
      if (value.indexOf('accesibility-page--') > -1) document.body.classList.remove(value);
    }
    document.body.classList.add(`accesibility-page--font-size${level}`);
  },[]);

  return (<span className="accessibility-tools--font-size">
    <span className="switch level1" data-level={1} onClick={applyFontSize}/>
    &nbsp;
    <span className="switch level2" data-level={2} onClick={applyFontSize}/>
    &nbsp;
    <span className="switch level3" data-level={3} onClick={applyFontSize}/>
  </span>)

}

class Navbar extends Component {

  constructor(props) {
    super(props);
    const { location, mainlogo, stickylogo = 'logo-www.png' } = this.props;
    this.state = {
      scroll: location.pathname === '/',
    };
    this.hamburgerMenuRef = React.createRef();
    this.logoSticky = require(`../../image/${stickylogo}`);
    this.logoMain = require(`../../image/${mainlogo}`);
  }

  componentDidMount() {
    const { location } = this.props;
    // apply high contrast
    if (window.localStorage.getItem('hc')){
      document.body.classList.add('hc');
    }
    if (location.state?.from) {
      const el = document.getElementById(location.state.from);
      if (el) {
        // const rect = el.getBoundingClientRect()
        setTimeout(() => {
          document.getElementById(location.state.from).scrollIntoView();
        }, 0);
      }
    }

    document.addEventListener('click',this.burgerMenuClickOutsideHandler.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('click',this.burgerMenuClickOutsideHandler.bind(this));
  }

  burgerMenuClickOutsideHandler(event) {
    const node = this.hamburgerMenuRef.current;
    const notClickedItself = node && (event.target !== node) && (event.target.parentElement !== node);
    if (notClickedItself) {
      const isOpen = Array.from(node.classList).indexOf('collapsed') === -1;
      if (isOpen) {
        $(node.dataset.target).collapse('hide')
      }
    }
  }

  applyHighContrast(){
    if (document.body.classList.contains('hc')){
      window.localStorage.removeItem('hc');
    } else{
      window.localStorage.setItem('hc', 1);
    }
    //
    document.body.classList.toggle('hc');
  }

  applyFontSize(){

  }

  render() {
    const { mClass, mContainer } = this.props;
    const { scroll } = this.state;
    return (
      <Sticky top={0} innerZ={999} activeClass="navbar_fixed">
        <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
          <div className={`container ${mContainer}`}>
            {/* logo */}
            <a className="navbar-brand logo_h" href="/">
              <img className="site_logo site_logo_main" src={this.logoMain} alt="Logotyp Alternatywy" />
              <img className="site_logo site_logo_sticky" src={this.logoSticky} alt="Logotyp Alternatywy" />
            </a>
            <a
              href="./"
              className="btn get-btn get-btn-two d-lg-none d-none login"
            >
              Login
            </a>
            <button ref={this.hamburgerMenuRef}
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <small>MENU</small>
            </button>
            <div
              className="collapse navbar-collapse offset"
              id="navbarSupportedContent"
            >
              <ul className="nav navbar-nav m-auto">
                { menu.map((item, k) => <li key={k} className="nav-item">
                  {item.to.search('https://') > -1 ?
                    <a className="nav-link" href={item.to} rel="noopener noreferrer" target="_blank"> {item.name}</a>
                    :
                    item.to.search('/') > -1 ?
                      <Link
                        className="nav-link"
                        to={item.to}>{item.name}</Link>
                    :
                    scroll ? <LinkScroll
                          className="nav-link"
                          activeClass="active"
                          to={item.to}
                          spy={true}
                          smooth={true}
                          offset={-86}
                          duration={500}
                        >
                          {item.name}
                        </LinkScroll>
                        :
                        <Link
                          className="nav-link"
                          to={{ pathname:'/', state: { from: item.to } }}>{item.name}</Link>

                  }
                </li>) }
              </ul>
              {/* login button */}
              <ul className="nav navbar-nav navbar-right">
                <li className="nav-item text-size d-inline-flex align-items-center">
                  <AccesibilityFontSize />
                  &nbsp;
                  <img src={require("../../image/invert-colors-button.png")} style={{width:24, height:24}} alt="Wersja dla niedowidzących" onClick={this.applyHighContrast.bind(this)}/>
                </li>
                <li className="nav-item d-inline-flex ">
                  <a href="https://uck-alternatywy.bip.gov.pl/" target={'_blank'} rel="noopener noreferrer">
                    <img src={require("../../image/logo-BIP.svg")} alt="BIP"/>
                  </a>
                </li>
                <li className="nav-item text-size d-inline-flex align-items-center">
                  <a href="https://tlumacz.migam.org/warszawa-ursynowskie-centrum-kultury-alternatywy-indiry-gandhi-9" target={'_blank'} rel="noopener noreferrer">
                    <img src={require("../../image/migam.png")} style={{width:24, height:24}} alt="Migam.org"/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </Sticky>
    );
  }
}

export default withRouter(Navbar);
