import React, {Component, useState} from 'react';
import Sectiontitle from '../Banner/Sectiontitle';
import useMobileDetect from 'use-mobile-detect-hook';
import RepertoryItems from "./RepertoryItems";
import {Splide, SplideSlide} from '@splidejs/react-splide';
import {ExtendedDescription} from "./RepertoryItemExtendDescription";
import {PageDataContext} from "../../services/context";

const months = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec','lipiec','sierpień','wrzesień','październik','listopad','grudzień'];

const RepertorySlidingView = ({ data }) => {

  const [isMobile] = useState(useMobileDetect().isMobile());
  return (<Splide
    options={{
      rewind: true,
      perPage: isMobile ? 1 : 3,
      width: '100%',
      pagination: false,
      gap: '.5rem',
    }}
  >
    { data.map((item, index) => <SplideSlide key={index}><RepertoryItems data={item} /></SplideSlide>)}
  </Splide>);
}

const RepertoryListView = ({ data }) => {
  return (
    <ul className="list-unstyled">
      { data.map((item) => {

        const date = new Date(item.date);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const hour = `${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;

        return (<li className="d-md-flex pt-4 pb-4 align-items-center justify-content-center"
                      style={{ borderBottom: '1px solid #ededed' }} key={item.link}>
          <div className="col-md-2">
            <h3 className="d-none d-md-block">{day}</h3>
            <span className="d-md-none d-sm-inline">{day} &nbsp;</span>
            <span>{month}</span>&nbsp;
            <span>g. {hour}</span>
          </div>
          <div className="my-md-0 my-3 col-md-7 col-sm-12 cursor-pointer">
            <strong>{item.title}</strong>
            <br/>
            <ExtendedDescription desc={item.desc}/>
          </div>
          <div className="col-md-3 col-sm-12 text-center">
            <a className="btn btn-primary" href={item.link} role="button">{item.free ? 'Odbierz wejściówkę' : 'Kup bilet'}</a>
          </div>
          </li>)
        })
      }
    </ul>);

}

class Repertory extends Component {
  static contextType = PageDataContext;

  constructor() {
    super();

    const d = new Date();

    this.state = {
      originalData: [],
      data: [],

      calendarMonths: new Set(),
      currentMonth: d.getMonth(),
      currentMonthName: months[d.getMonth()],

      viewType: 'waffle'
    }

  }

  componentDidMount() {

    const { repertory } = this.context.dataState;

    if (repertory) {
      const { sorted, calendarMonths } = repertory;
      let filteredData = sorted.filter(item => item.month === this.state.currentMonth);

        // if filtered items empty check every month till end year
      if (!filteredData.length) {
        let currMonth = this.state.currentMonth;
        while (!filteredData.length && currMonth < 13) {
          currMonth++;
          filteredData = sorted.filter(item => item.month === currMonth);
        }
        // change current month
        if (currMonth !== this.state.currentMonth) {
          this.setState({
            currentMonth: currMonth,
            currentMonthName: months[currMonth]
          });
        }
      }

      this.setState({
        calendarMonths: Array.from(calendarMonths.keys()),
        originalData: sorted,
        data: filteredData
      });

    }
  }
  /*
  changeMonth(dir) {
    let currentMonth = dir === 'next' ? this.state.currentMonth + 1 : this.state.currentMonth -1;
    currentMonth = currentMonth > 11 ? 0 : currentMonth;
    currentMonth = currentMonth < 0 ? 11 : currentMonth;

    const calendarMonths = Array.from(this.state.calendarMonths);
    if (calendarMonths.indexOf(currentMonth) === -1) return;

    this.setState({
      data: this.state.originalData.filter(item => item.month === currentMonth),
      currentMonth,
      currentMonthName: months[currentMonth]
    });
  }
  */
  changeMonth(currentMonth) {
    const calendarMonths = Array.from(this.state.calendarMonths);
    if (calendarMonths.indexOf(currentMonth) === -1) return;

    this.setState({
      data: this.state.originalData.filter(item => item.month === currentMonth),
      currentMonth,
      currentMonthName: months[currentMonth]
    });
  }

  changeView(viewType) {
    this.setState({
      viewType
    });
  }

  render() {
    return (
      <section className="blog_area" id="repertory">
        <div className="container">
          <Sectiontitle Title="Repertuar"/>
          <div className={'text-center repertory-month-selector'}>
            {/*<span onClick={() => this.changeMonth.bind(this)('prev')} style={{fontSize: 'xx-large',cursor:'pointer'}}> &#8249; </span>*/}
            {/*<strong style={{textTransform:'capitalize', fontSize: 'large'}}>{this.state.currentMonthName}</strong>*/}
            {/*<span onClick={() => this.changeMonth.bind(this)('next')} style={{fontSize: 'xx-large',cursor:'pointer'}}> &#8250;</span>*/}
            {
              this.state.calendarMonths?.length > 0 ?
                  this.state.calendarMonths.map((item, index) => <span key={item}>
                    <strong
                        className={ this.state.currentMonth === item ? 'active' : '' }
                        onClick={() => this.changeMonth.bind(this)(item)}
                    >
                      { months[item] }
                    </strong>
                    {index === this.state.calendarMonths.length - 1 ? '' : <span> | </span>}
                  </span>) : ''
            }
          </div>
          <p className={'text-center repertory-view-switch'}>
            <span>
              <span className={['switch', this.state.viewType === 'list' ? 'active' : ''].join(' ')} onClick={() => this.changeView.bind(this)('list')}>kalendarium</span>
              <span>&nbsp;|&nbsp;</span>
              <span className={['switch', this.state.viewType === 'waffle' ? 'active' : ''].join(' ')} onClick={() => this.changeView.bind(this)('waffle')}>lista wydarzeń</span>
            </span>
          </p>
          <br/>
          <div>
            {this.state.viewType === 'waffle' ? <RepertorySlidingView data={this.state.data} /> : <RepertoryListView data={this.state.data}/>}
          </div>
        </div>
      </section>
    )
  }
}

export default Repertory;
