import React, { useEffect } from "react";
import Navbar from "./component/Navbar/Navbar";
import Footer from "./component/Footer";
import jhonData from "./component/jhonData";
import PublicOrders from "./component/PublicOrders/PublicOrders";

export const PublicPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="body_wrapper">
          <Navbar mClass="dark_menu" mContainer="custome_container" mainlogo="logo.svg" />
          <PublicOrders />
          <Footer jhonData={jhonData} />
      </div>
    );
}
