import React, {Component} from 'react';
import {Link} from "react-router-dom";
class PressItems extends Component{
    render(){
        let {bTitle, bDetails, idx, image} = this.props;
        return(
            <div className="col-sm-12 col-lg-4 mb-4">
                <div className="blog_post">
                    <div className="blog_img">
                        <img className="img-fluid" src={require ("../../image/" + image)} alt=""/>
                    </div>
                    <div className="post_content">
                        <a href={`/press/${idx}`}><h2>{bTitle}</h2></a>
                        <div dangerouslySetInnerHTML={{__html: bDetails.replace(/\t/g, '')}}></div>
                        <Link
                          className="read_btn"
                          to={{
                              pathname:`/press/${idx}`,
                              state: { fromPress: true }
                          }}
                        >Więcej<i className="arrow_right"></i></Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default PressItems;