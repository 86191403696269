import React, {Component} from 'react';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import {Link} from "react-router-dom";
import useMobileDetect from "use-mobile-detect-hook";
import {PageDataContext} from "../services/context";
import AboutUsComponent from "../component/AboutUs/AboutUs";


class About extends Component {
  static contextType = PageDataContext;

  constructor(props) {
    super(props);

    this.state = {
      isMobile: useMobileDetect().isMobile()
    }
  }

  render() {
    const { dataState: {
      aboutDepartments
      }
    } = this.context;

    if ((!aboutDepartments || aboutDepartments.length === 0))
      return null;

    return (
      <section className="about_area" id="about">
        <div className="container">
          {/* description with photo slider */}
          <AboutUsComponent />
          {/* slider */}
          <div className="row" style={{padding: '2rem', marginTop: '50px'}}>
            <Splide
              options={{
                // rewind : true,
                type: 'loop',
                perMove: 1,
                perPage: this.state.isMobile ? 1 : 2,
                height: '500px',
                gap: '2rem',
                pagination: false,
                arrows: true,
                width: '100%'
              }}
            >
              {aboutDepartments.map((slide, index) => (
                <SplideSlide key={index}>
                  <Link to={{
                    pathname:`/aboutus`,
                    state: { fromNews: false }
                  }}>
                    <div className={'slide'} style={{backgroundImage: `url("${slide.featuredImage?.node?.mediaItemUrl}")`}}>
                      <div className={'slide-description'}>
                        <h3>{slide.title}</h3>
                        <span
                          dangerouslySetInnerHTML={{__html: `${slide.content.substr(0, this.state.isMobile ? 128 : 200)}...`}}/>
                        <br/>
                        <strong>więcej</strong>
                      </div>
                    </div>
                  </Link>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </section>
    )
  }
}
export default About;
