export function parseRepertoryResponse(data) {
  const calendarMonths = new Set();

  const sorted = data.map(d => {
    const ts = new Date(d.date);
    calendarMonths.add(ts.getMonth());
    return {...d, ts: ts.getTime(), month: ts.getMonth()};
  }).sort((a, b) => a.ts - b.ts);

  return {
    sorted,
    calendarMonths
  }
}
