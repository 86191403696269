import React, {Component} from 'react';
import {Link} from "react-router-dom";

class NewsItems extends Component {

  render() {
    const {image = '', idx} = this.props;
    return (<div className="slide d-flex">
      <Link
        to={{
          pathname:`/press/${idx}`,
          state: { fromNews: true }
        }}
      >
        <img alt="" src={image} style={{maxWidth: '100%'}}/>
        {/*  <div className="slide-photo" style={{backgroundImage: 'url(' + image + ')'}}/>*/}
      </Link>
    </div>);
  }
}

export default NewsItems;
