import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal/';
import useMobileDetect from "use-mobile-detect-hook";
class Sectiontitle extends Component {
  constructor(props) {
    super(props);

    const detectMobile = useMobileDetect();
    const isMobile = detectMobile.isMobile();

    this.state = {
      isMobile
    };
  }
  render(){
        const {Title, TitleP} = this.props;
        return(
            <div className={'section_title text-center ' + (this.state.isMobile ?  'mb_10': 'mb_40')}>
                <Reveal  effect="fadeInUp"><h2 className="mb_0 title_h2 t_color">{Title}</h2></Reveal>
                <Reveal  effect="fadeInUp" duration={1500}><p className="mb_0 title_p" dangerouslySetInnerHTML={{__html: TitleP}} /></Reveal>
                <Reveal  effect="fadeInLeft" duration={2000}><span className="bottom_line"></span></Reveal>
            </div>
        )
    }
}
export default Sectiontitle;
