import React, {useEffect, useContext, useState} from 'react';
import Navbar from './component/Navbar/Navbar';
import About from './component/About';
import MapContainer from './component/Map';
import Contact from './component/Contact';
import Footer from './component/Footer';
import jhonData from './component/jhonData';
import News from "./component/News/News";
import Repertory from "./component/Repertory/Repertory";
import VideoBanner from "./component/Banner/VideoBanner";
import useMobileDetect from "use-mobile-detect-hook";
import {PageDataContext} from "./services/context";
import {Loader} from "./component/Loader";

export const Home3 = () => {

  const data = useContext(PageDataContext);
  const [ready, setReady] = useState(false);

  const detectMobile = useMobileDetect();
  const isIos = detectMobile.isIos();

  useEffect(() => {
    if (data.dataState?.news.length) {
      setReady(true);
    }
  }, [data])

  return (
    <div className="body_wrapper">
      <Navbar mClass="dark_menu" mContainer="custome_container" mainlogo="logo.svg" />
      {
        ready ?
          <div>
            { !isIos && <VideoBanner /> }
            <News />
            <Repertory aClass='bg_color' />
            <About aClass='about_area bg_color' jhonData={jhonData} />
            <MapContainer />
            <Contact jhonData={jhonData} />
          </div>
        :
          <Loader></Loader>
      }
      <Footer jhonData={jhonData} />
    </div>
  )
}

