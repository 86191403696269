import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';

const footerLinks = [
  {
    name: 'Do pobrania',
    to: 'press'
  },
  {
    name: 'Zamówienia publiczne',
    to: 'public'
  },
  {
    name: 'Wynajem sal',
    to: 'rent'
  },
  {
    name: 'Dokumenty',
    to: 'ordinances'
  },
  {
    name: 'Deklaracja dostępności',
    to: 'availabilitydeclaration'
  }
];

class Footer extends Component {

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render() {
    let jhonData = this.props.jhonData;
    const date = new Date();
    return (
      <section className="footer-area">
        <div className="container">
          <div className="footer-content">
            <div className="logo wow fadeInDown" data-wow-delay="0.4s">
              <img onClick={() => this.scrollToTop()} src="https://serwer2131787.home.pl/autoinstalator/wordpressplugins/wp-content/uploads/2022/07/logotyp_alternatywy_poziome_kolorowe.png" alt="alternatywy logo poziome"/>
            </div>
            {/* socials */}
            <Fade top cascade>
              <ul className="list_style social-links">
                {
                  jhonData.socialLinks.map(item => {
                    return (
                      <li key={item.name}>
                        <a href={item.url} target={'_blank'} rel="noopener noreferrer"><i className={item.className}></i></a>
                      </li>
                    )
                  })
                }
              </ul>
            </Fade>
            {/*<h6>Made with <span>❤</span>️ by <a href="https://global-lab.pl">global-lab</a></h6>*/}
            <br/>
            <p>© {date.getFullYear()} Ursynowskie Centrum Kultury „Alternatywy”</p>
            <ul className="footer-links list-inline">
              {footerLinks.map((item, k) => <li key={k}><Link
                className="list-inline-item"
                replace={true}
                to={{ pathname: `/${item.to}`}}
              >{item.name}</Link></li>)}
              <li><a href={'https://alternatywy.s3.eu-central-1.amazonaws.com/pdf/statut+UCK+Alternatywy.pdf'} target={'_blank'} rel="noopener noreferrer">Statut</a></li>
              <li><a href={'https://alternatywy.s3.eu-central-1.amazonaws.com/pdf/alternatywy-rodo.pdf'} target={'_blank'} rel="noopener noreferrer">RODO</a></li>
              <li><Link className="list-inline-item" replace={true}  to={{ pathname: "/archive"}}>Archiwum</Link></li>
            </ul>
          </div>
        </div>
      </section>
    )
  }
}

export default Footer;
