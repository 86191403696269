import React, { useEffect } from "react";
import Navbar from "./component/Navbar/Navbar";
import Footer from "./component/Footer";
import jhonData from "./component/jhonData";
import Rent from "./component/Rent/Rent";

export const RentPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="body_wrapper">
          <Navbar mClass="dark_menu" mContainer="custome_container" mainlogo="logo.svg" />
          <Rent />
          <Footer jhonData={jhonData} />
      </div>
    );
}
