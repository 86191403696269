import React, {Component} from 'react';
import Sectiontitle from '../Banner/Sectiontitle';
import SplideCore from '@splidejs/splide/dist/js/splide.esm';
import {PageDataContext} from '../../services/context';

class AboutUs extends Component {
  static contextType = PageDataContext;

  constructor(props) {
    super(props);

    this.galleryWrapped = false;
    this.noGallery = false;
    this.container = null;

    this.setContainerRef = (element) => {
      this.container = element;
    }
  }

  componentDidMount() {
    this.renderGallery();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.renderGallery();
  }

  wrapGalleryWithSplide(galleryContent, className){
    const div = document.createElement('div');
    div.innerHTML = galleryContent;

    const gallery = div.querySelector('.splide-gallery-wrapper') || div.querySelector('.wp-block-gallery');

    if (gallery){
      const galleryCopy = gallery.cloneNode(true);
      const splidelist = document.createElement('div');
      splidelist.classList.add('splide__list');

      const children = Array.from(galleryCopy.children);
      children.forEach(item => {
        item.classList.add('splide__slide');
        splidelist.appendChild(item);
      });

      const splideTrack = document.createElement('div');
      splideTrack.appendChild(splidelist);
      splideTrack.classList.add('splide__track');
      splideTrack.classList.add('mx-auto');
      const splideWrapper = document.createElement('div');
      splideWrapper.appendChild(splideTrack);
      splideWrapper.classList.add('splide');
      if (className) {
        splideWrapper.classList.add(className);
      }

      gallery.replaceWith(splideWrapper);
    } else {
      // if no containers detected which can host gallery
      this.noGallery = true;
    }

    return div.innerHTML;
  }

  initSplideGallery() {
    if (document.querySelector('.splide.about-us-slider')){
      new SplideCore('.splide.about-us-slider', {
        arrows: true,
        autoplay: true,
        pagination: false,
        rewind: true,
      }).mount();
    }
  }

  renderGallery(){
    if (this.noGallery) {
      return;
    }

    if (this.container && !this.galleryWrapped){
      this.galleryWrapped = true;
      setTimeout(() => {
        this.initSplideGallery();
      }, 0);
    }
  }

  render() {
    const { dataState: {
      about
      }
    } = this.context;

    if (!about || about.length === 0) return null;
    const [aboutContent] = about;
    // wrap gallery before adding to DOM
    const str = this.wrapGalleryWithSplide(aboutContent.content, 'about-us-slider');

    return (
      <div>
        <Sectiontitle Title={aboutContent.title}/>
        <div ref={this.setContainerRef} dangerouslySetInnerHTML={{__html: str}} />
      </div>
    )
  }
}

export default AboutUs;
