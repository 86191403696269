import React, {Component} from 'react';
import {PageDataContext} from "../../services/context";
import Splide from '@splidejs/splide/dist/js/splide.esm';
import useMobileDetect from "use-mobile-detect-hook";
import Sectiontitle from "../Banner/Sectiontitle";

class Exhibition extends Component {
  static contextType = PageDataContext;
  constructor(props) {
    super(props);

    this.galleryWrapped = false;
    this.noGallery = false;
    this.container = null;

    this.setContainerRef = (element) => {
      this.container = element;
    }

    const detectMobile = useMobileDetect();
    this.state = {
      isMobile: detectMobile.isMobile(),
    }
  }

  wrapGalleryWithSplide(galleryContent){
    const div = document.createElement('div');
    div.innerHTML = galleryContent;

    const gallery = div.querySelector('.splide-gallery-wrapper') || div.querySelector('.wp-block-gallery-1');

    if (gallery){
      const galleryCopy = gallery.cloneNode(true);
      const splidelist = document.createElement('div');
      splidelist.classList.add('splide__list');

      const children = Array.from(galleryCopy.children);
      children.forEach(item => {
        item.classList.add('splide__slide');
        splidelist.appendChild(item);
      });

      const splideTrack = document.createElement('div');
      splideTrack.appendChild(splidelist);
      splideTrack.classList.add('splide__track');
      splideTrack.classList.add('mx-auto');
      const splideWrapper = document.createElement('div');
      splideWrapper.appendChild(splideTrack);
      splideWrapper.classList.add('splide');

      gallery.replaceWith(splideWrapper);
    } else {
      // if no containers detected which can host gallery
      this.noGallery = true;
    }

    return div.innerHTML;
  }

  initSplideGallery() {

    let options = {
      arrows: true,
      pagination: true,
    }

    if (this.state.isMobile) {
      options = {
        ...options,
        arrows: false,
        rewind: true,
        autoplay: true
      }
    }

    new Splide( '.splide', options).mount();
  }

  renderGallery(){
    if (this.noGallery) {
      return;
    }

    if (this.container && !this.galleryWrapped){
      this.galleryWrapped = true;
      setTimeout(() => {
        this.initSplideGallery();
      }, 0);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.renderGallery();
  }

  componentDidMount() {
    this.renderGallery();
  }

  render() {
    const { dataState: {exhibition} } = this.context;
    if (!exhibition || exhibition.length === 0) return null;
    const [galleryContent] = exhibition;
    // wrap gallery before adding to DOM
    const str = this.wrapGalleryWithSplide(galleryContent.content);

    return (
      <section className="exhibition_area" id="exhibition">
        <div className="container">
          <Sectiontitle Title={galleryContent.title}/>
          <div className="row" ref={this.setContainerRef} dangerouslySetInnerHTML={{__html: str}} />
        </div>
      </section>
    )
  }
}

export default Exhibition;
