import React , {Component} from 'react';
import Sectiontitle from '../Banner/Sectiontitle';
import Reveal from 'react-reveal/Reveal/';
import { PageDataContext } from '../../services/context';
import {Loader} from "../Loader";

class AvailabilityDeclaration extends Component{
    static contextType = PageDataContext;

    render(){
        const { dataState: {availabilityDeclaration} } = this.context;
        const [doc] = availabilityDeclaration;
        return(
            <section className="availabilitydeclaration_area" id="availabilitydeclaration">
                {

                    (!availabilityDeclaration || availabilityDeclaration.length === 0) ?
                      <Loader></Loader>
                    :
                      <div className="container">
                          {/*<Sectiontitle Title={doc.title} TitleP={doc.excerpt} />*/}
                          <Sectiontitle Title={doc.title} />
                          {/* Deklaracja dostępności */}
                          <Reveal effect="fadeInUp" duration={1000}>
                              <div className="row flex-column" dangerouslySetInnerHTML={{__html: doc.content}} />
                          </Reveal>                         
                      </div>
                }

            </section>
        )
    }
}
export default AvailabilityDeclaration;
