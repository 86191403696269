import React, {useContext, useEffect, useState} from "react";
import Navbar from "./component/Navbar/Navbar";
import Footer from "./component/Footer";
import jhonData from "./component/jhonData";
import AboutUsComponent from "./component/AboutUs/AboutUs";
import {PageDataContext} from "./services/context";

export const AboutUsPage = () => {
  const data = useContext(PageDataContext);
  const [aboutDepartments, setAboutDepartments] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data) {
      const { dataState: {
        aboutDepartments
        }
      } = data;
      if (aboutDepartments.length) {
        setAboutDepartments(aboutDepartments);
      }
    }
  }, [data]);

  return (
      <div className="body_wrapper">
          <Navbar mClass="dark_menu" mContainer="custome_container" mainlogo="logo.svg" />
          <section className="about_area" id="about">
            <div className="container html-content">
              <AboutUsComponent />
              <div>
                {aboutDepartments?.map(department => <p id={department.slug} key={department.slug}><h4>{department.title}</h4><span dangerouslySetInnerHTML={{__html: department.content}}></span><br/></p>)}
              </div>
            </div>
          </section>
          <Footer jhonData={jhonData} />
      </div>
    );
}
