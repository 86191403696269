const WP_URL = 'https://serwer2131787.home.pl/autoinstalator/wordpressplugins/index.php?graphql';
const REPPERTORY_URL = 'https://alternatywy.s3.eu-central-1.amazonaws.com/json/repertory.json';

export async function getNews() {
  try {
    const res = await fetch(WP_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        query: `query posts {
          news: posts(where: {categoryName: "aktualnosci", status: PUBLISH}) {
            edges {
              node {
                slug
                date
                title
                postId
                content
                featuredImage {
                  node {
                    mediaItemUrl
                    status
                  }
                }
                author {
                  node {
                    name
                  }
                }
                excerpt
              }
            }
          }
          newsarchive: posts(where: {categoryName: "aktualnosci archiwum", status: PUBLISH}) {
            edges {
              node {
                slug
                date
                title
                postId
                content
                featuredImage {
                  node {
                    mediaItemUrl
                    status
                  }
                }
                author {
                  node {
                    name
                  }
                }
                excerpt
              }
            }
          }
          publicorders: posts(where: {categoryName: "zamowienia publiczne", status: PUBLISH}) {
            edges {
              node {
                slug
                date
                title
                postId
                content
                featuredImage {
                  node {
                    mediaItemUrl
                    status
                  }
                }
                author {
                  node {
                    name
                  }
                }
                excerpt
              }
            }
          }
          exhibition: posts(first:1, where: {categoryName: "wystawy", status: PUBLISH}) {
            edges {
              node {
                slug
                date
                title
                postId
                content
                featuredImage {
                  node {
                    mediaItemUrl
                    status
                  }
                }
                author {
                  node {
                    name
                  }
                }
                excerpt
              }
            }
          }
          regulations: posts(where: {categoryName: "regulaminy", status: PUBLISH}) {
            edges {
              node {
                slug
                date
                title
                postId
                content
                featuredImage {
                  node {
                    mediaItemUrl
                    status
                  }
                }
                author {
                  node {
                    name
                  }
                }
                excerpt
              }
            }
          }
          about: posts(where: {categoryName: "o-nas", status: PUBLISH}) {
            edges {
              node {
                slug
                date
                title
                postId
                content
                featuredImage {
                  node {
                    mediaItemUrl
                    status
                  }
                }
                author {
                  node {
                    name
                  }
                }
                excerpt
              }
            }
          }
          rent: posts(where: {categoryName: "wynajem", status: PUBLISH}) {
            edges {
              node {
                slug
                date
                title
                postId
                content
                featuredImage {
                  node {
                    mediaItemUrl
                    status
                  }
                }
                author {
                  node {
                    name
                  }
                }
                excerpt
              }
            }
          }
          contact: posts(where: {categoryName: "kontakt", status: PUBLISH}) {
            edges {
              node {
                slug
                date
                title
                postId
                content
                featuredImage {
                  node {
                    mediaItemUrl
                    status
                  }
                }
                author {
                  node {
                    name
                  }
                }
                excerpt
              }
            }
          }
          press: posts(where: {categoryName: "do pobrania", status: PUBLISH}) {
            edges {
              node {
                slug
                date
                title
                postId
                content
                featuredImage {
                  node {
                    mediaItemUrl
                    status
                  }
                }
                author {
                  node {
                    name
                  }
                }
                excerpt
              }
            }
          }
          aboutdepartments: posts(where: {categoryName: "o-nas-dzialy", status: PUBLISH}) {
            edges {
              node {
                slug
                date
                title
                postId
                content
                featuredImage {
                  node {
                    mediaItemUrl
                    status
                  }
                }
                author {
                  node {
                    name
                  }
                }
                excerpt
              }
            }
          }
          availabilitydeclaration: posts(where: {categoryName: "deklaracja-dostepnosci", status: PUBLISH}) {
            edges {
              node {
                slug
                date
                title
                postId
                content
                featuredImage {
                  node {
                    mediaItemUrl
                    status
                  }
                }
                author {
                  node {
                    name
                  }
                }
                excerpt
              }
            }
          }
        }
        `,
      }),
    });
    return await res.json();
  } catch (err) {
    console.error('>>> ERR :: graphql getNews', err);
  }
}

export async function getRepertory() {
  try {
    const res = await fetch(REPPERTORY_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    return await res.json();
  } catch (err) {
    console.error('>>> ERR :: getRepertory', err);
  }
}
