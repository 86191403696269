import React, { useEffect } from "react";
import Navbar from "./component/Navbar/Navbar";
import Footer from "./component/Footer";
import jhonData from "./component/jhonData";
//import VideoBanner from "./component/Banner/VideoBanner";
import NewsArchive from "./component/News/NewsArchive";

export const ArchivePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="body_wrapper">
          <Navbar mClass="dark_menu" mContainer="custome_container" mainlogo="logo.svg" />          
          <NewsArchive />
          <Footer jhonData={jhonData} />
      </div>
    );
}