import React, {Component} from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon, TwitterShareButton, WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import {PageDataContext} from "../../services/context";
import Sectiontitle from "../Banner/Sectiontitle";
import {Loader} from "../Loader";

class RepertoryItems extends Component {
  static contextType = PageDataContext;

  constructor(props) {
    super(props);
    this.state = {
      shareUrl: `https://alternatywy.art/press/${props.id}`,
    };
  }

  render() {
    const { dataState: { press, news, newsArchive, aboutDepartments }} = this.context;
    const { id } = this.props;

    if ((!news || news.length === 0)
        && (!newsArchive || newsArchive.length === 0)
        && (!press || press.length === 0)
        && (!aboutDepartments || aboutDepartments.length === 0))
        return null;

    let article = [...press,...news,...newsArchive, ...aboutDepartments].find(item => item.postId === parseInt(id,10));

    // if (!article) return null;
    const { content, title, thumb, media, video } = article;

    return (
        <section className="press_area">
          {
            !article ?
              <Loader></Loader>
              :
              <div className="container">
                <div className="col">
                  <Sectiontitle Title={title || 'Informacja prasowa'}/>
                  {/* share */}
                  <div className={'text-right'}>
                    <FacebookShareButton url={this.state.shareUrl} quote={title} hashtag={"alternatywy"}>
                      <FacebookIcon size={32}/>
                    </FacebookShareButton>&nbsp;
                    <TwitterShareButton url={this.state.shareUrl} title={title} via={'alternatywy_art'} related={['alternatywy_art']}>
                      <TwitterIcon size={32}/>
                    </TwitterShareButton>&nbsp;
                    <WhatsappShareButton url={this.state.shareUrl} title={title} separator={':'} >
                      <WhatsappIcon size={32}/>
                    </WhatsappShareButton>
                  </div>
                  {/* content */}
                  <br/>
                  {thumb && <div>
                    <img className="d-block w-100" alt="promo obrazek" src={thumb}/>
                  </div>}
                  <br/>
                  <div dangerouslySetInnerHTML={{__html: content.replace(/\t/g, '')}}></div>
                  <br/>
                  {/*  media */}
                  {media && media.length >0 &&
                    <div className="carousel slide" data-ride="carousel" data-interval="3000">
                      <ol className="carousel-indicators">
                        {media.map((item, k) => <li data-target="#carouselExampleIndicators" data-slide-to={k} className={`${k === 0 ? "active" : ""}`} />)}
                      </ol>
                      <div className="carousel-inner">
                        {media.map((item, k) => <div className={`carousel-item ${k === 0 ? 'active' : ''}`}>
                          <img className="d-block w-100" alt={'galeria obrazow'} src={item}/>
                        </div>)}
                      </div>
                    </div>
                  }
                  {video && <video width="100%" controls>
                    <source type="video/mp4" src={video}/>
                  </video>}
                </div>
              </div>
          }
        </section>
    )
  }
}

export default RepertoryItems;
