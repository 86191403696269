import React , {Component} from 'react';
import { Map,GoogleApiWrapper, Marker } from 'google-maps-react';

export class MapContainer extends Component {
    constructor(props) {
      super(props);

      this.state = {
        stores: [{lat: 52.14844598530891, lng: 21.04460829999292}],
      }
    }

    displayMarkers = () => {
      return this.state.stores.map((store, index) => {
        return <Marker key={index} id={index} position={{
         lat: store.lat,
         lng: store.lng,
       }}
       />
      })
    }

    render() {
        const mapStyles = {
            width: '100%',
            height: '500px',
        };
      return (
          <section className="contact_map">
              <Map
              google={this.props.google}
              zoom={17}
              initialCenter={{ lat: 52.14844598530891, lng: 21.04460829999292}}
              style= {mapStyles}
            >
              {this.displayMarkers()}
            </Map>
          </section>
      );
    }
  }

  export default GoogleApiWrapper({
    apiKey: 'AIzaSyBCB4y0SztfLo236n4JECwTvIQd-VecF2Y'
  })(MapContainer);