import React, {Component} from 'react';
import {Loader} from "../Loader";
import Sectiontitle from '../Banner/Sectiontitle';
import {PageDataContext} from '../../services/context';

class Ordinances extends Component {
  static contextType = PageDataContext;

  render() {
    const { dataState: {regulations} } = this.context;
    const [ordinances] = regulations;
    return (
      <section className="ordinances_area" id="ordinances">
        { (!regulations || regulations.length === 0) ?
          <Loader></Loader>
        :
        <div className="container html-content">
          <Sectiontitle Title={ordinances.title} TitleP={ordinances.excerpt} />
          <div dangerouslySetInnerHTML={{__html: ordinances.content}} />
        </div>
        }
      </section>
    )
  }
}

export default Ordinances;
