import React , {Component} from 'react';
import Reveal from 'react-reveal/Reveal/';
import NewsItemsWide from "./NewsItemsWide";
import {Splide, SplideSlide, SplideTrack} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import useMobileDetect from 'use-mobile-detect-hook';
import {PageDataContext} from '../../services/context';
import Sectiontitle from "../Banner/Sectiontitle";

function CustomArrows () {

    return (<div className="splide__arrows">
        <button className="splide__arrow splide__arrow--prev">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40"><path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"/></svg>
        </button>
        <button className="splide__arrow splide__arrow--next">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40"><path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"/></svg>
        </button>
    </div>)
}

class NewsArchive extends Component{
    static contextType = PageDataContext;

    constructor(props) {
        super(props);

        this.refSplide = React.createRef();
        const detectMobile = useMobileDetect();
        const isMobile = detectMobile.isMobile();

        this.splideOptions = {
            type   : 'loop',
            width: '100%',
            gap   : '1rem',
            padding: isMobile ? 0 : { left: 0, right: '10rem' },
            arrows: true,
            drag: false,
            pagination: false,
            waitForTransition: true
        }
        this.state = {
            currentItemIdx: 1,
            itemsCount: 0,
            newsArchive:[],
            isMobile
        }
    }
    componentDidMount() {
        if ( this.refSplide.current ) {
            const len = this.refSplide.current.splide.length;
            this.setState({
                itemsCount: this.refSplide.current.splide.length,
                nextItemIdx: this.state.currentItemIdx + 1 < len ? this.state.currentItemIdx + 1 : len,
            });
        }
    }

    render(){
        let { dataState: {newsArchive} } = this.context;

        if (!newsArchive || newsArchive.length === 0) return null;
        const { isMobile } = this.state;
        // if only one news item disable splide functions
        if (newsArchive.length === 1) {
            this.splideOptions.arrows = false;
            this.splideOptions.type = 'slide';
            this.splideOptions.padding = 0;
        }

        return(
            <section className="blog_area_two" id="news">
                <div className="container">
                    {isMobile && <Sectiontitle Title="Archiwum Aktualności"/> }
                    <Reveal effect="fadeInUp" duration={1000}>
                        <div className="row">
                                {!isMobile && <h2 className={'slide-title'}>Archiwum aktualności </h2> }
                                <Splide ref={this.refSplide}
                                        hasTrack={false}
                                        options={this.splideOptions}>
                                    <SplideTrack>
                                        {
                                            newsArchive.map((item, key) =>
                                            <SplideSlide key={key}>
                                                <NewsItemsWide idx={item.postId} bTitle={item.title} bDate="" bIntro={item.excerpt} image={item.featuredImage?.node?.mediaItemUrl}/>
                                            </SplideSlide>)
                                        }
                                    </SplideTrack>
                                    {/* custom arrows */}
                                    { newsArchive.length > 1 && <CustomArrows /> }
                            </Splide>
                        </div>
                    </Reveal>
                </div>
            </section>
        )
    }
}
export default NewsArchive;
