import React, { useEffect } from "react";
import Navbar from "./component/Navbar/Navbar";
import Footer from "./component/Footer";
import jhonData from "./component/jhonData";
import Ordinances from "./component/Ordinances/Ordinances";

export const OrdinancesPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="body_wrapper">
          <Navbar mClass="dark_menu" mContainer="custome_container" mainlogo="logo.svg" />
          <Ordinances />
          <Footer jhonData={jhonData} />
      </div>
    );
}
