import React, { Component } from "react";

class VideoBanner extends Component {

  constructor(props) {
    super(props);
    this.bannerSectionRef = React.createRef();
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    if (this.videoRef && this.videoRef.current) {
      this.videoRef.current.addEventListener('pause', (event) => {
        if (this.bannerSectionRef) {
          setTimeout(()=>{
            // eslint-disable-next-line no-unused-expressions
            this.bannerSectionRef?.current?.classList.add('d-none');
            window.sessionStorage.setItem('videoShown', 'true');
          }, 300);
        }
      });
    }
  }

  render() {
    let videoShown = window.sessionStorage.getItem('videoShown');
    videoShown = (videoShown === 'true');
    return (
      <section id="home" className={videoShown ? 'd-none' : ''} ref={this.bannerSectionRef}>
        <div className="banner_area_two">
          {!videoShown && <video ref={this.videoRef} autoPlay={true} playsInline={true} muted={true} style={{height: '90vh' }} src={'https://alternatywy.s3.eu-central-1.amazonaws.com/video/logo_white.mp4#t=1,3.9'} />}
          <div className="container">
            <div className="banner_content">
            </div>
          </div>
        </div>
        <hr/>
      </section>
    );
  }
}

export default VideoBanner;
